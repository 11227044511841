<template>
    <div class="page-wrap">        
        <div  class="cont-page" v-if="!useType">
            <div v-for="(meauitem,index) in meauClassList" :key="index">             
                <div>
                    <div class="tittle" v-if='haveClass.indexOf(meauitem.id)!==-1'><div class="lanshu"></div>{{meauitem.name}}</div>
                    <div class="carditem-kuang" >
                        <div v-for="(item,index) in cardList" :key="index"  >
                            <!-- H5-payment  若pay_type 是付呗  则不展示h5支付 -->
                            <div class="one-kuang" v-if="item.class_id == meauitem.id  && (item.permissions_rote!='H5-payment' || pay_type_h5 != 2)" @click="chooseItem(item.permissions_rote)"> 
                                <div class="left-img"  v-if='meauitem.id == 1 || meauitem.id == 4'>       
                                    <img :src="item.icon"/>                     
                                </div>
                                <div class="left_erge"  v-if='meauitem.id == 2'><img :src="item.icon"/>  </div>
                                <div class="left_sange"  v-if='meauitem.id == 3'><img :src="item.icon"/>  </div>
                                <div class="right_con" >
                                    <div class="item_tit">{{item.permissions_name}}</div>
                                    <div class="item_jianjie">暂无简介~</div>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>                
            </div>
        </div> 
        <Seal v-if="useType === 'seal-management'" @goBack="afresh"></Seal>   <!--印章管理 √-->
        <User v-if="useType === 'user-management'" @goBack="afresh"></User>   <!--用户管理 √-->
        <Applet v-if="useType === 'wx-applet'" @goBack="afresh"></Applet>       <!--微信小程序 √-->
        <Payment v-if="useType === 'H5-payment'" @goBack="afresh"></Payment>        <!--H5支付 √-->
        <Oem v-if="useType === 'oem-config'" @goBack="afresh"></Oem>        <!--OEM配置 √-->
        <Cloud v-if="useType === 'cloud-storage'" @goBack="afresh"></Cloud>     <!--云存储 √-->
        <Apply-seal v-if="useType === 'seal-application'" @goBack="afresh"></Apply-seal>    <!--印章申请 -->
        <Approve-seal v-if="useType === 'seal-examine'" @goBack="afresh"></Approve-seal>    <!--印章审批 √-->
        <Privacy v-if="useType === 'privacy-agreement'" @goBack="afresh"></Privacy>     <!--隐私协议  √ -->
        <Defalut v-if="useType === 'signer-management'" @goBack="afresh"></Defalut>     <!--签署者管理 √-->
        <Ticket v-if="useType === 'contract-label'" @goBack="afresh"></Ticket>      <!--合同标签 √-->
    </div>
</template>

<script>
import Seal from './components/seal'
import User from './components/user'
import Applet from './components/applet'
import Payment from './components/payment'
import Oem from './components/oem'
import Cloud from './components/cloud'
import ApplySeal from './components/applySeal'
import ApproveSeal from './components/approveSeal'
import Privacy from './components/privacy'
import Defalut from "./components/defalut";
import Ticket from "./components/ticket";
import config from '@/config/index'
import { get_menuclass } from '@/api/center'
export default {
    name: "index",
    components:{
        Seal,
        User,
        Applet,
        Oem,
        Cloud,
        Payment,
        ApproveSeal,
        Privacy,
        Defalut,
        Ticket,
        ApplySeal
    },
    data(){
        return{
            cardList: [],
            meauClassList: [],
            haveClass: [],
            imgCof:config.apiUrl,
            pay_type_h5: '2'
        }
    },
    computed: {
        settingList(){
            return this.$store.state.settingMenu
        },
        useType(){
            return this.$store.state.activeType
        }
    },
    created() {
        this.pay_type_h5 = localStorage.getItem("pay_type_h5")
        // localStorage.getItem("pay_type_h5", data.pay_type ? data.pay_type : 0 ) //是否展示h5支付 pay_type 为2不显示H5支付
        this.$store.commit('changePollingSpin', true)
        this.get_menuclass()   
        this.cardList = this.settingList
        this.cardList.forEach(item=>{
            this.haveClass.push(item.class_id)            
        })
    },
    methods:{
        chooseItem(type){
            this.$store.commit('setType', type)
        },
        afresh(){
            this.pay_type_h5 = localStorage.getItem("pay_type_h5")
            this.$store.commit('setType', '')
        },
        get_menuclass(){
            get_menuclass().then(res=>{
                console.log('权限分类',res)     
                this.meauClassList = res.data.reverse()       
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        } 
    }
}
</script>

<style scoped lang="scss">
.cont-page {
    background: #fff;
    width: 100%;
    padding: 24px;
    .tittle{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 800;
        display: flex;    
        margin-bottom:12px;    
        .lanshu {
            width: 3px;
            margin: 8px 4px ;
            height: 14px;
            line-height: 24px;
            background: #4C7AE8;
        }
    }
    .carditem-kuang{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 8px;
    } 
    .one-kuang {
        width: 278px;
        height: 88px;
        background: #EFF2F9;
        margin-bottom:24px;
        margin-right: 24px;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        cursor: pointer;
        &:hover {
            border: 1px solid #4877E8;
        }
        .left-img {
            background: #77A4E9;
            border-radius: 4px;
            width: 60px;
            height: 60px;
            margin-top: 6px;
            margin-bottom: 8px;
            margin-left: 6px ;
            img{
                width: 60px;
                height: 60px;
            }
        }
        .left_erge{
            background: #FA954B;
            border-radius: 4px;
            width: 60px;
            height: 60px;
            margin-top: 6px;
            margin-bottom: 8px;
            margin-left: 6px ;
            img{
                width: 60px;
                height: 60px;
            }
        }
        .left_sange {
            background: #57DD79;
            border-radius: 4px;
            width: 60px;
            height: 60px;
            margin-top: 6px;
            margin-bottom: 8px;
            margin-left: 6px ;
            img{
                width: 60px;
                height: 60px;
            }
        }
        .right_con {
            margin-top:6px;
            margin-left:8px;
            .item_tit{
                font-size: 18px;
                color: #545F78;
                font-weight: 550;
            }
            .item_jianjie {
                margin-top: 8px;
                overflow:hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical; 
            }
        }        
    }
}
</style>
