<template>
    <div class="seal-page">
        <div v-if="needMe">
            <div class="top_change">
                <div class="l_where">
                    系统设置<span>/</span>印章申请
                </div>
                <div class="r_back" @click="goBack">
                    <i class="iconfont icon-fanhui"></i>
                </div>
            </div>
            <!-- <div class="seal-nav">
                <div class="seal_group">
                    <div class="seal_nav_item" v-for="item in 1" :key="item">
                        <div class="seal-img flex-c">
                            <img src="../../../assets/imgs/258.jpg"/>
                            <div class="do_it"></div>
                            <div class="do_btn">
                                <div class="l-icons flex-c"><i class="iconfont icon-chakan2"></i></div>
                                <div class="l-icons flex-c"><i class="iconfont icon-shanchu"></i></div>
                            </div>
                        </div>
                        <div class="use_btn flex-c">使用</div>
                    </div> 
                </div>
            </div> -->
            <div class="table-wrap">
                <div class="table-btn blue" @click="creatSeal">创建印章</div>
                <Table :columns="platformColumns" :data="storeList" style="margin-top: 20px">
                    <template slot-scope="{ row }" slot="name">
                        <div @click="openImg(row.seal_id.seal_img)" v-if="row.seal_id.seal_content"
                            style="cursor:pointer;">{{ row.seal_id.seal_content }}</div>
                        <img @click="openImg(row.seal_id.seal_img)" :src="row.seal_id.seal_img" v-else
                            style="cursor:pointer;width:50px;height:50px">
                    </template>
                    <template slot-scope="{ row }" slot="status">
                        <div>{{ row.status === 0 ? '待审核' : row.status === 1 ? '审核成功' : row.status === 2 ? '审核失败' : '' }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="cont">
                        <div v-if="row.contract_arr == null || row.contract_arr.contract_name == undefined || row.contract_arr.contract_name == ''"></div>
                        <div class="cont-cla" @click="goSeeCont(row)" v-else>{{ row.contract_arr.contract_name }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="operation">
                        <Button type="error" v-if="row.status === 0" size='small' @click="goDel(row)">撤销</Button>
                    </template>
                </Table>
            </div>
            <div class="choose-pop" v-if="needChoose">
                <div class="pop"></div>
                <div class="modal fixed">
                    <div class="modal-title">印章申请</div>
                    <div class="close-icon" @click="cancelIt">
                        <Icon type="md-close" size="24" />
                    </div>
                    <div class="top_wrap">
                        <div style="padding: 0px 30px;">
                            <div class="wrap_label">企业印章(必选)</div>
                            <div class="seal-list">
                                <div class="seal-img flex-c" :class="formItem.seal_id === item.id ? 'active' : ''"
                                    @click="chooseImg(item)" v-for="(item, index) in seal_list" :key="index">
                                    <img :src="item.seal_path" alt="">
                                    <div v-if="formItem.seal_id === item.id" class="just-one"> 已选择</div>
                                </div>
                            </div>
                            <div class="wrap_label">选择合同</div>
                            <Select v-model="formItem.contract_id" filterable clearable style="width: 400px">
                                <Option v-for="item in contList" :value="item.id" :key="item.id">{{
                                    item.contract_name
                                }}</Option>
                            </Select>
                            <span style="margin-top:4px" v-if="formItem.contract_id == '' || formItem.contract_id == undefined || formItem.contract_id == 0">
                                <div class="wrap_label">使用次数</div>
                                <Input v-model="formItem.seal_num" type="number" placeholder="请输入使用次数" style="width: 400px" />
                            </span>
                            <div class="wrap_label">申请原因(必选)</div>
                            <Input v-model="formItem.apply_reason" type="textarea" :rows="4" style="width: 400px"
                                placeholder="请输入申请原因" />
                            <div style="display: flex;align-items: center;margin: 8px 0">
                                <div class="wrap_label-s">短信提醒</div>
                                <div style="margin-left: 16px">
                                    <i-switch size="large" v-model="formItem.is_sms" :value="formItem.is_sms"
                                        :true-value="1" :false-value="0" @on-change="onchangeIsShow">
                                        <span slot="open">开启</span>
                                        <span slot="close">关闭</span>
                                    </i-switch>
                                </div>
                            </div>
                        </div>
                        <div class=" footer-cla">
                            <Button @click="cancelIt">取消</Button>
                            <Button style="margin-left: 20px" type="primary" @click="creatIt">申请</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Allcontracts @titleSync="goToView" ref="contracts"></Allcontracts>
    </div>
</template>

<script>
import { sealList, sealCreate, applyList, stay_signcontract, del_applyrecord } from '../../../api/interFace';
// import allcontracts from './seecomponents/allcontracts';
import Allcontracts from './allcontractss'
export default {
    name: "index",
    components: {
        Allcontracts
    },
    data() {
        return {
            contList: [],
            needMe: true,
            platformColumns: [
                {
                    title: '#',
                    key: 'id',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '印章名称',
                    key: 'account',
                    slot: 'name',
                    align: 'center'
                },
                {
                    title: '合同名称',
                    key: 'contract_id',
                    slot: 'cont',
                    align: 'center'
                },

                {
                    title: '使用次数',
                    key: 'seal_num',
                    align: 'center'
                },
                {
                    title: '申请原因',
                    key: 'apply_reason',
                    align: 'center'
                },
                {
                    title: '申请时间',
                    key: 'created_at',
                    align: 'center'
                },
                {
                    title: '申请状态',
                    key: 'status',
                    slot: 'status',
                    align: 'center'
                },
                {
                    title: '审核时间',
                    key: 'updated_at',
                    align: 'center'
                },
                {
                    title: '拒绝原因',
                    key: 'refuse_reason',
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'status',
                    align: 'center',
                    slot: 'operation',
                }
            ],
            storeList: [],
            seal_list: [],
            value6: '',
            value: '',
            needChoose: false,
            formItem: {
                seal_id: '',
                seal_num: 1,
                contract_id: 0,
                apply_reason: '',
                is_sms: 1
            },
            storePrams: {
                limit: 10,
                page: 1,
                total: 0,
            },
        }
    },
    created() {
        this.getSeal()
        this.getList()
        this.stay_signcontract()
    },
    methods: {
        openImg(e) {
            this.$Modal.info({
                title: '预览图片',
                content: '<img style="width:250px;height:250px" src="' + e + '" />'
            });
        },
        goSeeCont(e) {
            console.log(e)
            this.$nextTick(() => {
                this.needMe = false
                this.$refs.contracts.openIt(e.contract_arr)
            })
        },
        goToView() {
            this.needMe = true
        },
        getList() {
            applyList(this.storePrams).then(res => {
                this.storeList = res.data.data || []
            })
        },
        creatSeal() {
            this.needChoose = true
        },
        goBack() {
            this.$emit('goBack')
        },
        agree(e) {
            console.log(e)

        },
        onchangeIsShow(e) {
            console.log(e)
            console.log(this.formItem)
        },
        stay_signcontract() {
            stay_signcontract().then(res => {
                console.log('res', res)
                this.contList = res.data
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        creatIt() {
            if (this.formItem.seal_id == '') return this.$Message.error('请选择印章')
            this.formItem.contract_id = (typeof this.formItem.contract_id == "undefined") ? 0 : this.formItem.contract_id;

            sealCreate(this.formItem).then(res => {
                this.$Message.success(res.msg)
                this.reset()
                this.getList()
                this.needChoose = false
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        cancelIt() {
            this.reset()
            this.needChoose = false
        },
        chooseImg(e) {
            this.formItem.seal_id = e.id
        },
        getSeal() {
            sealList().then(res => {
                this.seal_list = res.data || []
                console.log(res)
            })
        },
        clearDel(e) {
            console.log(e)
        },
        goDel(e) {
            console.log(e)
            this.$Modal.confirm({
                title: '撤销提示',
                content: '请问您是否确认撤销此条印章申请吗，撤销后无法恢复!!!',
                onOk: () => {
                    del_applyrecord({ id: e.id }).then(res => {
                        this.$Message.success(res.msg)
                        this.getList()
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        reset() {
            this.formItem = {
                seal_id: '',
                seal_num: 1,
                apply_reason: '',
                contract_id: 0,
                is_sms: 1
            }
        }
    }
}
</script>

<style scoped lang="scss">
.footer-cla {
    border-top: 1px solid #e8eaec;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 12px 32px;
}

.modal-title {
    font-size: 16px;
    color: #17233d;
    border-bottom: 1px solid #e8eaec;
    // margin-bottom: 25px;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 16px;
    margin-bottom: 8px;
}

.close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 10px;
    cursor: pointer;
}

.seal-page {
    .top_change {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        height: 60px;

        .l_where {
            //position: absolute;
            //left: 30px;
            //top: 50%;
            //transform: translateY(-50%);
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1B2742;

            span {
                color: #4877E8;
            }
        }

        .r_back {
            .iconfont {
                //position: absolute;
                //right: 30px;
                //top: 50%;
                //transform: translateY(-50%);
                font-size: 26px;
                cursor: pointer;

                &:hover {
                    color: #4877E8;
                }
            }
        }
    }

    .go-back {
        width: 100px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 6px;
        font-size: 14px;
        font-weight: bold;
        color: #545F78;
        cursor: pointer;
    }

    .seal-nav {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        margin-top: 20px;  

        .seal_group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .seal_nav_item {
                margin-right: 20px;

                .seal-img {
                    width: 200px;
                    height: 200px;
                    background: #FFFFFF;
                    border: 1px solid #C8CEDA;
                    border-radius: 6px;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;

                    img {
                        width: 140px;
                        height: 140px;
                        object-fit: cover;
                    }

                    &:hover .do_it {
                        display: inline-block;
                    }

                    &:hover .do_btn {
                        display: flex;
                    }

                    .do_it {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: #191A1F;
                        opacity: 0.3;
                        display: none;
                    }

                    .do_btn {
                        display: flex;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        display: none;

                        .l-icons {
                            flex: 1;
                            height: 40px;
                            background: white;
                            cursor: pointer;

                            &:first-child {
                                border-right: 1px solid #C8CEDA;

                                .iconfont {
                                    color: #4877E8;
                                    font-size: 24px;
                                }
                            }

                            &:last-child {
                                .iconfont {
                                    color: #FD5454;
                                    font-size: 21px;
                                }
                            }
                        }
                    }
                }

                .use_btn {
                    width: 70px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #C8CEDA;
                    border-radius: 4px;
                    font-size: 16px;
                    color: #545F78;
                    cursor: pointer;
                    margin-top: 10px;

                    &:hover {
                        border-color: #4877E8;
                        color: #4877E8;
                    }
                }
            }
        }
    }

    .table-wrap {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        margin-top: 20px;

        .blue {
            margin-left: unset;
            margin-top: 20px;
        }
    }

    .choose-pop {
        position: relative;

        .modal {
            width: 600px;
            background: white;
            border-radius: 8px;

            .top_wrap {
                background: white;

                box-sizing: border-box;
                border-radius: 4px;
                box-shadow: 2px 5px 2px -2px #e7e5e0;
            }
            .new-title {
                font-size: 18px;
                font-weight: bold;
                color: black;
            }

            .wrap_label {
                font-size: 14px;
                color: #515a6e;
                font-weight: 500;
                margin-top: 4px;
                margin-bottom: 4px;

                &:first-child {
                    margin-top: unset;
                }
            }

            .wrap_label-s {
                font-size: 14px;
                color: #515a6e;
                font-weight: 500;
            }

            .seal-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                // max-height: ;
                max-height: 240px;
                overflow: auto;
            }

            .seal-img {
                width: 140px;
                height: 140px;
                background: #FFFFFF;
                border: 1px solid #C8CEDA;
                border-radius: 6px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                margin-right: 10px;
                margin-bottom: 10px;

                img {
                    width: 110px;
                    height: 110px;
                    object-fit: cover;
                }

                &:hover {
                    border: 1px solid #1A73E8;
                }
            }

            .seal-img.active {
                border: 1px solid #1A73E8;
            }

        }
    }

    .just-one {
        right: 0;
        top: 0;
        background: linear-gradient(to right, #6884F3 0%, #3E5FE2 100%) !important;
        border-radius: 4px;
        font-size: 12px;
        color: #fff;
        position: absolute;
    }

    .cont-cla {
        color: #1A73E8;
        cursor: pointer;
    }
}

.juzhong {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
