<template>
    <div class="user_page">
        <div class="top_change" @click="goBack">
            <div class="l_where">
                系统设置<span>/</span>云存储
            </div>
            <div class="r_back" @click="goBack">
                <i class="iconfont icon-fanhui"></i>
            </div>
        </div>
        <div class="card_bg">
            <div class="cloudTF">
                <div class="nav_title" style="padding:0px 0px">云存储开关：</div>
                <div class="nav_switch">
                    <i-switch @on-change="chooOEM" v-model="formCloud.real_status" :true-value="1" :false-value="0"
                        size="large">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </div>
            </div>
            <div v-if="showCloud">
                <div class="input_nav flex">
                    <div class="label">云服务商：</div>
                    <div>
                        <RadioGroup v-model="formCloud.service_type" @on-change="changeConfig">
                            <Radio label="1">阿里云</Radio>
                            <Radio label="2">腾讯云</Radio>
                            <Radio label="3">七牛云</Radio>
                        </RadioGroup>
                    </div>
                </div>
                <div class="input_nav flex">
                    <div class="label">空间域名Domain：</div>
                    <div class="val">
                        <Input v-model="formCloud.domain" placeholder="请输入空间域名Domain" />
                    </div>
                </div>
                <div class="input_nav flex">
                    <div class="label">accessKey：</div>
                    <div class="val">
                        <Input v-model="formCloud.access_key" placeholder="请输入accessKey" />
                    </div>
                </div>
                <div class="input_nav flex">
                    <div class="label">secretKey：</div>
                    <div class="val">
                        <Input v-model="formCloud.secret_key" placeholder="请输入secretKey" />
                    </div>
                </div>
                <div class="input_nav flex">
                    <div class="label">存储空间名称：</div>
                    <div class="val">
                        <Input v-model="formCloud.storage_title" placeholder="请输入存储空间名称" />
                    </div>
                </div>
                <div class="input_nav flex">
                    <div class="label">所属地域：</div>
                    <div class="val">
                        <Input v-model="formCloud.area" placeholder="请输入所属地域" />
                    </div>
                </div>
                <div class="xuxian"></div>
                <div class="s_b">
                    <Button type="primary" @click="submitCloud">确定</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getcloud, savecloud, storageSwitch } from '../../../api/interFace'
export default {
    name: "user",
    components: {
    },
    data() {
        return {
            inputVal: '',
            accessoriesList: '',
            showCloud: false,
            formCloud: {
                status: true,
                real_status: 0,
                service_type: '1',
                domain: "",
                access_key: "",
                secret_key: "",
                storage_title: "",
                area: ""
            },
        }
    },
    created() {
        this.$store.commit('changePollingSpin', true)
        this.getCloudData()
    },
    methods: {
        goBack() {
            this.$emit('goBack')
        },
        changeConfig(v) {
            this.formCloud.service_type = v
        },
        submitCloud() {
            if (this.formCloud.service_type == '') { this.$Message.warning('请选择云服务商'); return; }
            if (this.formCloud.domain == '') { this.$Message.warning('请填写空间域名'); return; }
            if (this.formCloud.access_key == '') { this.$Message.warning('请填写accessKey'); return; }
            if (this.formCloud.secret_key == '') { this.$Message.warning('请填写secretKey'); return; }
            if (this.formCloud.storage_title == '') { this.$Message.warning('请填写存储空间名称'); return; }
            if (this.formCloud.area == '') { this.$Message.warning('请填写所属地域'); return; }
            this.formCloud.status = this.formCloud.status ? 1 : 0;
            savecloud(this.formCloud).then(res => {
                this.$Message.success(res.msg)
                this.formCloud.status_old = this.formCloud.status;
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        chooOEM(e) {
            if (e == 0) {
                this.showCloud = false
            } else {
                this.showCloud = true
            }
            if (this.formCloud.status == 1) {
                storageSwitch({ status: 0 }).then(res => {
                    this.$Message.success(res.msg)
                    this.showCloud = false
                    this.formCloud.status = 0
                }).catch(res => {
                    this.$Message.error(res.msg)
                })
            }
        },
        getCloudData() {
            getcloud().then(res => {
                this.formCloud = {
                    status: res.data.status == 1 ? true : false,
                    real_status: res.data.status,
                    service_type: res.data.service_type,
                    domain: res.data.domain,
                    access_key: res.data.access_key,
                    secret_key: res.data.secret_key,
                    storage_title: res.data.storage_title,
                    area: res.data.area
                };
                if (this.formCloud.status) {
                    this.showCloud = true
                }
                this.formCloud.status_old = this.formCloud.status;
            }).catch({

            })
        },
    }
}
</script>

<style scoped lang="scss">
.cloudTF {
    display: flex;
    user-select: none;
    text-align: right;
    justify-content: center;
    align-items: center;
    width: 233px;
    margin-bottom: 25px;
    margin-top: 5px
}

.user_page {
    .top_change {
        width: 100%;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        height: 70px;
        .l_where {
            font-size: 18px;
            font-weight: bold;
            color: #1B2742;
            width: 150px;
            span {
                color: #4877E8;
            }
        }
        .r_back {
            width: 100%;
            cursor: pointer;
            text-align: end;
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }        
    }
    .card_bg {
        width: 100%;
        margin-top: 20px;
        border-radius: 8px;
        background: #fff;
        padding: 50px 60px;
        box-sizing: border-box;
        min-height: 450px;
        min-height: 75vh;
        position: relative;
        .xuxian {
            width: 100%;
            border-top: 1px solid #e8eaec;
            position: absolute;
            bottom: 65px;
            margin-left: -60px;
        }
        .s_b {
            position: absolute;
            bottom: 20px;
            right: 24px;
        }
        .input_nav {
            margin-bottom: 25px;
            .label {
                font-size: 14px;
                font-weight: bold;
                color: #545F78;
                text-align: right;
                width: 140px;
            }
            .long {
                width: 130px;
            }
            .val {
                width: 350px;                              
            }
        }
    }
}
</style>
