<template>
    <div class="user_page">
        <div class="top_change" @click="goBack">
            <div class="l_where">
                系统设置<span>/</span>OEM配置
            </div>
            <div class="r_back" @click="goBack">
                <i class="iconfont icon-fanhui"></i>
            </div>
        </div>
        <div class="card_bg">
            <div class="cloudTF">
                <div class="nav_title">OEM开关：</div>
                <div class="nav_switch">
                    <i-switch @on-change="chooOEM" v-model="formOem.real_status" :true-value="1" :false-value="0" size="large">
                        <span slot="open">开启</span>
                        <span slot="close">关闭</span>
                    </i-switch>
                </div>
            </div>
            <div v-if="showOEM">
                <div class="input_nav flex">
                    <div class="label">网站标题：</div>
                    <div class="val">
                        <Input v-model="formOem.site_title" placeholder="请输入网站标题" />
                    </div>
                </div>
                <div class="input_nav flex">
                    <div class="label">底部版权信息：</div>
                    <div class="val">
                        <Input v-model="formOem.floor_cr" placeholder="请输入底部版权信息" />
                    </div>
                </div>
                <div class="input_nav flex">
                    <div>
                        <div class="label">站点logo：</div>
                        <div class="label_tj">(推荐尺寸32*32)</div>
                    </div>
                    <div class="val">
                        <div class="else_up">
                            <div class="pic_wrap">
                                <div class="img_list" v-if="formOem.site_logo">
                                    <img :src="formOem.site_logo">
                                    <div class="close" @click="formOem.site_logo = ''">
                                        <Icon type="ios-close-circle-outline" size="30" />
                                    </div>
                                </div>
                                <div v-else>
                                    <Upload :show-upload-list="false" :headers='headers' :action="imageAction"
                                        :on-format-error="handleFormatError" :format="['jpg', 'jpeg', 'png',]"
                                        :on-success="handleSuccessLogo">
                                        <div class="upload_style">
                                            <Icon type="ios-add" size="60" />
                                        </div>
                                    </Upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input_nav flex">
                    <div>
                        <div class="label">登录页背景图：</div>
                        <div class="label_tj">(推荐尺寸1920*1080)</div>
                    </div>
                    <div class="val">
                        <div class="else_up">
                            <div class="pic_wrap">
                                <div class="img_list" v-if="formOem.login_bg">
                                    <img :src="formOem.login_bg">
                                    <div class="close" @click="formOem.login_bg = ''">
                                        <Icon type="ios-close-circle-outline" size="30" />
                                    </div>
                                </div>
                                <div v-else>
                                    <Upload :show-upload-list="false" :headers='headers' :action="imageAction"
                                        :on-format-error="handleFormatError" :format="['jpg', 'jpeg', 'png',]"
                                        :on-success="handleSuccessLogin">
                                        <div class="upload_style">
                                            <Icon type="ios-add" size="60" />
                                        </div>
                                    </Upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input_nav flex">
                    <div>
                        <div class="label">首页海报图：</div>
                        <div class="label_tj">(推荐尺寸690*280)</div>
                    </div>
                    <div class="val">
                        <div class="else_up">
                            <div class="pic_wrap">
                                <div class="img_list" v-if="formOem.applet_poster">
                                    <img :src="formOem.applet_poster">
                                    <div class="close" @click="formOem.applet_poster = ''">
                                        <Icon type="ios-close-circle-outline" size="30" />
                                    </div>
                                </div>
                                <div v-else>
                                    <Upload :show-upload-list="false" :headers='headers' :action="imageAction"
                                        :on-format-error="handleFormatError" :format="['jpg', 'jpeg', 'png',]"
                                        :on-success="handleSuccessPic">
                                        <div class="upload_style">
                                            <Icon type="ios-add" size="60" />
                                        </div>
                                    </Upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="xuxian"></div>
                <div class="s_b">
                    <Button type="primary" @click="submitOem">确定</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookies } from '@/utils/helper'
import config from '@/config/index'
import { saveoem, getoem, chooseOEM } from '../../../api/interFace'
export default {
    name: "user",
    components: {
    },
    data() {
        return {
            inputVal: '',
            accessoriesList: '',
            showOEM: false,
            imageAction: config.apiUrl + '/adminapi/common/uploads',
            headers: { 'Authori-zation': getCookies('token') },
            formOem: {
                status: true,
                real_status: 0,
                site_logo: "",
                login_bg: "",
                site_title: "",
                floor_cr: "",
                applet_poster: ''
            },
        }
    },
    created() {
        this.$store.commit('changePollingSpin', true)
        this.getOemData()
    },
    methods: {
        goBack() {
            this.$emit('goBack')
        },
        getOemData() {
            getoem().then(res => {
                this.formOem = {
                    status: res.data.status == 1 ? true : false,
                    real_status: res.data.status,
                    site_logo: res.data.site_logo,
                    login_bg: res.data.login_bg,
                    applet_poster: res.data.applet_poster,
                    site_title: res.data.site_title,
                    floor_cr: res.data.floor_cr
                };
                if (this.formOem.status == 1) {
                    this.showOEM = true
                }
                this.formOem.status_old = this.formOem.status;
            }).catch({
            })
        },
        chooOEM(e) {
            if (e == 0) {
                this.showOEM = false
            } else {
                this.showOEM = true
            }
            if (this.formOem.status == 1) {
                chooseOEM({ status: 0 }).then(res => {
                    this.$Message.success(res.msg)
                    this.showOEM = false
                    this.formOem.status = 0
                }).catch(res => {
                    this.$Message.error(res.msg)
                })
            }
        },
        // 文件类型上传失败
        handleFormatError() {
            this.$Message.warning('暂不支持上传此类型文件')
        },
        handleSuccessLogo(v) {
            this.formOem.site_logo = v.data.src
        },
        handleSuccessLogin(v) {
            this.formOem.login_bg = v.data.src
        },
        handleSuccessPic(v) {
            this.formOem.applet_poster = v.data.src
        },
        submitOem() {
            if (this.formOem.site_logo == '') { this.$Message.warning('请上传站点logo'); return; }
            if (this.formOem.login_bg == '') { this.$Message.warning('请上传登录页背景图'); return; }
            if (this.formOem.applet_poster == '') { this.$Message.warning('请上传首页海报图'); return; }
            if (this.formOem.site_title == '') { this.$Message.warning('请填写网站标题'); return; }
            if (this.formOem.floor_cr == '') { this.$Message.warning('请填写底部版权'); return; }
            this.formOem.status = this.formOem.status ? 1 : 0;
            saveoem(this.formOem).then(res => {
                this.$Message.success(res.msg)
                this.formOem.status_old = this.formOem.status;
                this.formOem.status = 1
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
    }
}
</script>

<style scoped lang="scss">
.cloudTF {
    display: flex;
    user-select: none;
    text-align: right;
    justify-content: center;
    align-items: center;
    width: 165px;
    margin-bottom: 25px;
    margin-top: 5px;
}

.user_page {
    .top_change {
        width: 100%;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        height: 70px;
        .l_where {
            font-size: 18px;
            font-weight: bold;
            color: #1B2742;s
            span {
                color: #4877E8;
            }
        }
        .r_back {
            width: 200px;
            cursor: pointer;
            text-align: end;
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }        
    }
    .card_bg {
        width: 100%;
        margin-top: 20px;
        border-radius: 8px;
        background: #fff;
        padding: 50px 60px;
        box-sizing: border-box;
        min-height: 450px;
        position: relative;
        .xuxian {
            width: 100%;
            border-top: 1px solid #e8eaec;
            position: absolute;
            bottom: 65px;
            margin-left: -60px;
        }
        .s_b {
            position: absolute;
            bottom: 20px;
            right: 24px;
        }
        .input_nav {
            margin-bottom: 25px;
            .label {
                font-size: 14px;
                font-weight: bold;
                color: #545F78;
                text-align: right;
                width: 120px;
            }
            .long {
                width: 130px;
            }
            .val {
                width: 350px;
                .else_up {
                    display: flex;
                    .upload_style {
                        width: 120px;
                        height: 120px;
                        border-radius: 4px;
                        border: 1px solid #C8CEDA;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        cursor: pointer;
                    } 
                    .pic_wrap {
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: 12px;
                    } 
                    .img_list {
                        width: 100px;
                        height: 100px;
                        position: relative;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        border-radius: 4px; 
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 4px;
                        } 
                        .close {
                            position: absolute;
                            top: -15px;
                            right: -15px;
                            cursor: pointer;
                            color: #dd0000;
                            display: none;
                        }
                    } 
                    .img_list:hover .close {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

.label_tj {
    font-size: 12px;
    color: #5e5e5e;
    opacity: 0.5;
    user-select: none;
    text-align: end;
}
</style>
