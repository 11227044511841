import request from '@/utils/applets-request'


/*
 * 小程序登录二维码
 * */
export function appletsLogin (data) {
    return request({
        url: '/',
        method: 'post',
        data
    })
}
/*
 * 小程序登录二维码
 * */
export function appletsCheck (data) {
    return request({
        url: '/appletscheck',
        method: 'post',
        data
    })
}
