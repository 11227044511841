<template>
    <div>
        <div class="dabox" v-if="showSecond">
            <div class="leftbox">               
                <Card class="buttonCss" dis-hover :bordered="false">
                    <Button class="operationBtn" @click="backOut">返回</Button>
                </Card>
            </div>
            <div class="right-box">
                <div v-if="img_list.length > 0">
                    <div v-for="item in img_list" class="bg-img" :key="item">
                        <img :src="item">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var that;
import { getpdf, get_contractloglist } from '../../../api/interFace'

export default {
    name: 'allcontracts',
    props: {
        titleSync: Object
    },
    data() {
        return {
            data: {},
            list: [],
            editRecordList: [],
            template_html_content: "",
            img_list: [],
            timer: '48',
            needCheck: true,
            downPath: '',
            showSecond: false
        }
    },
    created() {
        that = this;
        this.timer = Date.parse(new Date());
    },
    methods: {
        backOut() {
            this.showSecond = false
            this.$emit('titleSync', false)
        },
        openIt(e) {
            this.showSecond = true
            this.img_list = e.contract_imgs || []
            this.downPath = e.contract_path
            this.needId = e.id
            this.get_contractloglist()
        },     
        get_contractloglist() {
            get_contractloglist({ contract_id: this.needId }).then(res => {
                this.editRecordList = res.data
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        }       
    }
}

</script>

<style scoped>
.dabox {
    width: 100%;
    display: flex;
}

.leftbox {
    width: 210px;
    background: white;
    position: fixed;
    height: 100vh;
    overflow: auto;
    text-align: center;
}

.right-box {
    flex: 1;
}

.buttonCss {
    margin-top: 20px;
}

.bg-img {
    width: 800px;
    margin: auto;
    background: white;
    margin-bottom: 2px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
}

.bg-img img {
    width: 100%;
    object-fit: cover;
    margin: auto;
    display: inline-block;
}
</style>
<style>
.new-more tr {
    height: 30px;

}

.new-more table {
    width: 100%;
    border: 1px solid #777777;
}

.new-more td,
.new-more th {
    border: 1px solid #777777;
}

.MsoNormalTable {
    margin-left: unset !important;
}
</style>
