<template>
    <div class="seal-page">
        <div class="top_change" @click="goBack">
            <div class="l_where">
                系统设置<span>/</span>印章管理
            </div>
            <div class="r_back" @click="goBack">
                <i class="iconfont icon-fanhui"></i>
            </div>
        </div>
        <div class="seal-nav">
            <div class="flex">
                <div class="seal_title">我的企业印章</div>
                <!-- 160*160 -->
                <Alert type="warning" show-icon style="margin-top: 12px;margin-left: 24px" v-if="$store.state.identity!=2" >选择上传印章,上传印章图片大小为320*320,图片格式为Png</Alert>
            </div>           
            <div class="choose_style">
                <div class="table-btn blue" @click="creatSeal">创建印章</div>
                <Upload
                    :show-upload-list="false"
                    :headers='headers'
                    :action="imageAction"
                    :on-format-error="handleFormatError"
                    :on-progress="handleProgress"
                    :format="['jpg','jpeg','png',]"
                    :on-success="uploadImgSuccess"
                    v-if='$store.state.identity == 1||$store.state.identity ==3'
                    >
                    <div class="table-btn edit">
                        上传印章
                    </div>
                </Upload>
            </div>
            
            <div class="seal_group">
                <div class="seal_nav_item" v-for="(item,index) in list" :key="index">
                    <div  :class="item.status === 0?'seal-img flex-c':'seal-img flex-c haschoose'" >
                        <div class="right_top"  v-if="item.status !== 0">已使用</div>
                        <img :src="item.seal_path"  />
                        <div class="do_it"></div>
                        <div class="do_btn">
                            <div class="l-icons flex-c" @click="seeImg(item.seal_path,'1')"><i class="iconfont icon-chakan2"></i></div>
                            <div class="l-icons flex-c" @click="delSeal(item.id)"><i class="iconfont icon-shanchu"></i></div>
                        </div>
                    </div>
                    <div class="out_height">
                        <div class="use_btn flex-c" v-if="item.status === 0" @click="useSeal(item.id)">使用</div>
                    </div>
                </div>
            </div>
        </div>
        <!--    确认印章上传提示框-->
        <div  v-if="showSeal">
            <div class="pop"></div>
            <div class="seal_card fixed flex-c">
                <Icon type="md-close" size="30" @click="closeImg" class="close" />
                <div class="require_seal">
                <img :src="showPic">
                </div>
                <Button type="primary" v-if="justSee" style="margin-top: 20px" @click="checkUp">确认上传</Button>
            </div>
        </div>
        <!-- CA创建印章弹出层-->
        <!-- <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal = false"></modal> -->
        <!-- ca新创建印章弹出层-->
        <Modal  title="创建印章" v-model="showModal" width="600"  :styles="{top: '20%'}"  :mask-closable="false">
            <div style="width:100%;">
                <div class="flex one_input" style="margin-left:100px">
                    印章类型：
                    <Select   v-model="newCaSeal.qysseal_type" style="width:200px;" @on-change="goCaChange">
                        <Option v-for="i in newType" :value="i.id" :key="i.id">{{ i.name }}</Option>
                    </Select>     
                </div>
                <div class="flex one_input"  style="margin-left:100px">
                    横排文字：<Input  style="width: 250px" v-model="newCaSeal.strname"  placeholder="请输入横排文字"></Input>                      
                </div>
                <div class="flex one_input" style="margin-left:100px" v-if="newCaSeal.qysseal_type!=2">
                    企业编码：<Input  style="width: 250px" v-model="newCaSeal.strnumber"  placeholder="请输入企业编码"></Input>
                </div>
                
            </div>
            <div class="fenbu" slot="footer">        
                <Button @click="showModal = false" class="">取 消</Button>
                <Button @click="goCaSubmit" type="primary" class="">确认</Button>
            </div>
        </Modal>
        <!-- 非ca印章创建 -->
        <Modal  title=" 创建印章" v-model="showYz" width="700"  :styles="{top: '20%'}"  :mask-closable="false">
            <div class='flex'>
                <div style="width:350px;height:310px">
                    <div class="flex one_input"  v-if="$store.state.identity == 1 ||$store.state.identity == 3">
                        <div class="text_red" style="width:6px">*</div>公司名称：<Input  style="width: 200px" v-model="seal.str"  placeholder="请输入公司名称"></Input>
                        <Tooltip  placement="bottom">
                            <Icon type="ios-help-circle-outline" size='20' class="icon-cla" style="margin-left:8px" />
                            <div slot="content" style="font-size:12px;white-space: normal;max-width:400px">
                                <p>公司名称最少五个字,少于五个字不生成印章</p>
                            </div>
                        </Tooltip>
                    </div>
                    <div class="flex one_input" ><div class="text_red" style="width:6px"></div>企业编码：<Input  style="width: 200px" v-model="seal.strnumber"  placeholder="请输入企业编码"></Input></div>
                    <div class="flex one_input">
                        <div class="text_red" style="width:6px"></div>印章类型：<Input  style="width: 200px" v-model="seal.strname" maxlength='7'  placeholder="请输入印章类型"></Input>
                        <Tooltip  placement="bottom">
                            <Icon type="ios-help-circle-outline" size='20' class="icon-cla" style="margin-left:8px" />
                            <div slot="content" style="font-size:12px;white-space: normal;max-width:400px">
                                <p>印章类型最多七个字</p>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <!-- 印章 -->
                <div >
                    <div class="yz_cla" ><div><canvas id="my_canvas"  width="320px" height="320px" ></canvas></div></div>
                    <div class="yulan">印章预览</div>
                </div>
            </div>
            <div class="fenbu" slot="footer">        
                <Button @click="showYz = false" class="">取 消</Button>
                <Button @click="submit" type="primary" class="">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import modal from '@/components/modal/index'
import { getCookies } from '@/utils/helper'
import { comCreateSeal } from '@/utils/yinzhang'
import config from '@/config/index'
import { addImgsseal , seallist , editstamp , deleteStamp , stampaddsign,authStatus } from '../../../api/interFace'
export default {
    name: "seal",
    components: {
        modal
    },
    data() {
        return {
            modalInfo: {
                title: '创建印章',
                inputList: [
                    {
                        label: '印章类型',
                        inputType: 'text',
                        inputVal: ''
                    },
                    {
                        label: '公司名称',
                        inputType: 'text',
                        inputVal: ''
                    },
                    {
                        label: '企业编码',
                        inputType: 'number',
                        inputVal: ''
                    }
                ]
            },
            imageAction: config.apiUrl + '/adminapi/common/sigin_uploads',
            headers: {'Authori-zation': getCookies('token')},
            list:[],
            showModal: false,
            showSeal: false,
            justSee: true,
            showYz: false,
            showPic:'',
            relative_path:'',
            seal: {
                type: 1,
                str: '',
                strnumber: '',
                strname: '',
                qysseal_type: '',
                seal_imgs: "",//印章图片地址
            },
            newType: [
                {id:1, name:'公章'},
                {id:2, name:'专用章'}
            ],
            newCaSeal:{
                type: 1,
                str: '',
                strnumber: '',
                strname: '',
                qysseal_type: '',
                seal_imgs: "",//印章图片地址
            }
        }
    },
    created() {
        this.getlist()
    },
    watch: {
        get_seal_str: {
            handler: function() {
                this.isCanShow()
            },
        }, 
        get_seal_strname: {
            handler: function() {
                this.isCanShow()
            },
        }, 
        get_seal_strnumber: {
            handler: function() {
                this.isCanShow()
            },
        }, 
        get_seal_qysseal_type: {
            handler: function() {
                this.isCanShow()
            },
        },        
    },
    computed:{
        get_seal_str: function() {
            return this.seal.str
        },
        get_seal_strname: function() {
            return this.seal.strname
        },
        get_seal_strnumber: function() {
            return this.seal.strnumber
        },
        get_seal_qysseal_type: function() {
            return this.seal.qysseal_type
        },
    },
    methods: {
        isCanShow(){            
            if(this.$store.state.identity == 1 ||this.$store.state.identity == 3){
                //公司名称至少应该有两个字符串
                if(this.seal.str.length>4){                    
                    this.gocanva()
                }else{
                    let c = document.getElementById("my_canvas");  
                    let cxt = document.getElementById("my_canvas").getContext("2d");      
                    c.height = document.getElementById("my_canvas").height;
                    console.log('c',c)
                    console.log('cxt',cxt)
                    comCreateSeal({canvas:document.getElementById("my_canvas"), company:'', name:'', number:'', companyTight:false});
                }  
            }
        },
        // 若为CA获取信息
        getStatus(){
            authStatus().then(res=>{
                this.newCaSeal.strname = res.data.company_name?res.data.company_name:''
            }).catch(res=>{
                this.$Message.error(res.msg)
            })
        },
        gocanva(){
            //清空画布
            let c = document.getElementById("my_canvas");  
            let cxt = document.getElementById("my_canvas").getContext("2d");      
            c.height = document.getElementById("my_canvas").height;
            console.log('cxt',cxt)
            // 复现画布
            comCreateSeal({canvas:document.getElementById("my_canvas"), company:this.seal.str, name:this.seal.strname, number:this.seal.strnumber, companyTight:false});           
        },
        getVal(e) {
            this.seal.str = e[0]
            this.seal.strnumber = e[1]
            this.seal.qysseal_type = e[2]
            this.seal.strname = e[2]
            stampaddsign(this.seal).then(async res => {
                this.$Message.success(res.msg)
                this.showModal =false
                this.getlist()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        creatSeal(){
            if(this.$store.state.identity == 1 ||this.$store.state.identity == 3){
                this.removeData()
                this.showYz = true
            }else{
                this.showModal = true
                this.reset()
            }      
        },
        removeData(){
            this.seal.str = ''
            this.seal.strnumber = ''
            this.seal.qysseal_type = ''
            this.seal.strname = ''
            this.gocanva()
        },
        submit(){
            if(this.seal.str == '') return this.$Message.error('请填写公司名称')
            // if(this.seal.strname=='') return this.$Message.error('请填写印章类型')    
            if(!(this.seal.str.length > 4)) return this.$Message.error('公司名称不能少于五个字')  
            let pageData = document.getElementById("my_canvas").toDataURL("image/png");
            console.log('pagedata',pageData) 
            this.seal.seal_imgs = pageData
            stampaddsign(this.seal).then(async res => {
                this.$Message.success(res.msg)
                this.showYz =false
                this.seal.seal_imgs = ''
                this.getlist()
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        goCaChange(e){
            if(e==1){
                this.newCaSeal.strname = '公章'
            }else {
                this.newCaSeal.strname = '专用章'
            }
            console.log(e)
        },
        goCaSubmit(){           
            if(this.newCaSeal.qysseal_type==''){
                return  this.$Message.error('请选择公章类型')
            }           
            stampaddsign(this.newCaSeal).then(async res => {
                this.$Message.success(res.msg)
                this.showModal =false
                this.getlist()
                this.newCaSeal.strname = ''
                this.newCaSeal.qysseal_type = ''
                this.newCaSeal.strnumber = ''
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        reset(){  
            if (this.$store.state.identity == 1 ||this.$store.state.identity == 3) {
                this.modalInfo= {
                    title: '创建印章',
                    inputList: [
                        {
                            label: '公司名称',
                            inputType: 'text',
                            inputVal: ''
                        },
                        {
                            label: '企业编码',
                            inputType: 'text',
                            inputVal: ''
                        },
                        {
                            label: '印章类型',
                            inputType: 'text',
                            inputVal: ''
                        }
                    ]
                }
            } else {
                this.modalInfo= {
                    title: '创建印章',
                    inputList: [                        
                        {
                            label: '印章类型',
                            inputType: 'select',
                            datalist: [
                                {id:1, name:'公章'},
                                {id:2, name:'专用章'}
                            ],
                            inputVal: ''
                        },
                        {
                            label: '公司名称',
                            inputType: 'text',
                            inputVal: ''
                        },
                        {
                            label: '企业编码',
                            inputType: 'text',
                            inputVal: ''
                        }
                    ]
                }
            }

        },
        seeImg(path){
            this.showPic = path
            this.showSeal = true
            this.justSee = false
        },        
        goBack(){
            this.$emit('goBack')
        },
        getlist(){
            seallist({type:'1'}).then(async res => {
                this.list = res.data;
            }).catch(res => {
                console.log(res)
                this.list = [];
            })
        },
        delSeal(id){
            this.$Modal.confirm({
                title: '删除提示',
                content: '请问您是否确认删除此印章，删除后无法恢复！！！',
                onOk: () => {
                    deleteStamp({id:id}).then(async res => {
                        this.getlist()
                        this.$Message.success(res.msg)
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        useSeal(id){
            editstamp({id:id}).then(async res => {
                this.getlist()
                this.$Message.success(res.msg)
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        checkUp (){
            addImgsseal({seal_path:this.relative_path,type:'1'}).then(res=>{
                this.$Message.success(res.msg);
                this.showSeal = false
                this.getlist()
            }).catch(res=>{
                this.$Message.error(res.msg);
            })
        },
        closeImg(){
            this.showPic = ''
            this.relative_path = ''
            this.showSeal = false
        },
        handleProgress(){
            const msg = this.$Message.loading({
                content: '上传中...',
                duration: 0
            });
            setTimeout(msg, 3000);
        },
        //添加图片上传图片成功
        uploadImgSuccess (e) {
            this.showPic = e.data.src
            this.relative_path = e.data.relative_path
            this.showSeal = true
            this.$Message.destroy()
            if(e.code == 400){
                this.$Message.error(e.msg)
                this.showSeal = false
            }else {
                this.$Message.success(e.msg);
            }            
        },
        //文件上传类型错误
        handleFormatError () {
            this.$Message.warning('暂不支持上传此格式');
        },
    }
}
</script>

<style scoped lang="scss">
.icon-cla {
    cursor: pointer;
}
.yulan {
    text-align: center;
    font-size: 16px;
    margin-top:8px
}
.one_input {
    margin: 32px 8px ;
    margin-bottom: 21px;
}
.text_red {    
    color: red;
}
.yz_cla {
    width: 304px;
    height: 304px;
    border: 1px solid #4877E8;
}
.seal-page {
    .top_change {
        width: 100%;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        height: 60px;
        .l_where {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #1B2742;
            span {
                color: #4877E8;
            }
        }
        .r_back {
            width: 200px;
            cursor: pointer;
            text-align: end;
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }
    }
    .go-back {
        width: 100px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 6px;
        font-size: 14px;
        font-weight: bold;
        color: #545F78;
        cursor: pointer;
    }
    .seal-nav {
        width: 100%;
        min-height: 700px;
        padding: 24px;
        box-sizing: border-box;
        border-radius: 8px;
        background: white;
        margin-top: 20px;
        .seal_title {
            font-size: 18px;
            font-weight: bold;
            color: #545F78;
        }
        .choose_style {
            display: flex;
            align-items: center;
            margin-top: 12px;
            .blue {
                margin-right: 20px;
            }
        }
        .haschoose{
            border: 1px solid #4877E8!important;
            position: relative;
        }
        .right_top {
            position: absolute;
            top: 0;
            right: 0;
            padding: 2px 6px;
            z-index: 11;
            color: #FFFFFF;
            background: linear-gradient(to right, #6884F3  0%, #3E5FE2 100%);
            border-bottom-left-radius: 5px;
        }
        .seal_group {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 30px;
            .seal_nav_item {
                margin-right: 20px;
                margin-bottom: 20px;
                .seal-img {
                    width: 200px;
                    height: 200px;
                    background: #FFFFFF;
                    border: 1px solid #C8CEDA;
                    border-radius: 6px;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    img{
                        width: 140px;
                        height: 140px;
                        object-fit: cover;
                    }
                    &:hover .do_it{
                        display: inline-block;
                    }
                    &:hover .do_btn{
                        display: flex;
                    }
                    .do_it {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: #191A1F;
                        opacity: 0.3;
                        display: none;
                    }
                    .do_btn {
                        display: flex;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        display: none;
                        .l-icons {
                            flex: 1;
                            height: 40px;
                            background: white;
                            cursor: pointer;
                            &:first-child{
                            border-right: 1px solid #C8CEDA;
                                .iconfont{
                                    color: #4877E8;
                                    font-size: 24px;
                                }
                            }
                            &:last-child{
                                .iconfont{
                                color: #FD5454;
                                font-size: 21px;
                                }
                            }
                        }
                    }
                }
                .out_height {
                    width: 70px;
                    height: 34px;
                    margin-top: 10px;
                }
                .use_btn {
                    width: 70px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid #C8CEDA;
                    border-radius: 4px;
                    font-size: 16px;
                    color: #545F78;
                    cursor: pointer;
                    &:hover{
                        border-color: #4877E8;
                        color: #4877E8;
                    }
                }
            }
        }
    }

    .seal_card {
        width: 360px;
        height: 360px;
        background: white;
        border-radius: 5px;
        flex-direction: column;
        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
        .require_seal {
            width: 220px;
            height: 220px;
            border-radius: 6px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
        }
    }
}

</style>
