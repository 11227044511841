<template>
    <div class="user_page">
        <div class="top_change" @click="goBack">
            <div class="l_where">
                系统设置<span>/</span>H5支付
            </div>
            <div class="r_back" @click="goBack">
                <i class="iconfont icon-fanhui"></i>
            </div>
        </div>
        <div class="card_bg">
            <div class="input_nav flex">
                <div class="label">应用id：</div>
                <div class="val">
                    <Input v-model="h5Paylist.appid" placeholder="请输入应用id" />
                </div>
            </div>
            <div class="input_nav flex">
                <div class="label">微信支付商户号：</div>
                <div class="val">
                    <Input v-model="h5Paylist.mchid" placeholder="请输入微信支付商户号" />
                </div>
            </div>
            <div class="input_nav flex">
                <div class="label">微信支付平台证书序列号：</div>
                <div class="val">
                    <Input v-model="h5Paylist.serial_no" placeholder="请输入微信支付平台证书序列号" />
                </div>
            </div>
            <div class="input_nav flex">
                <div class="label">微信支付V3密钥：</div>
                <div class="val">
                    <Input v-model="h5Paylist.key" placeholder="请输入微信支付V3密钥" />
                </div>
            </div>
            <div class="input_nav flex">
                <div class="label">微信支付私钥证书(key)：</div>
                <div class="val">
                    <div class="else_up">
                        <div class="pic_wrap">
                            <div class="img_list" v-if="h5Paylist.key_cert">
                                <img src="../../../assets/imgs/22.png" />
                                <div class="close" @click="h5Paylist.key_cert = ''">
                                    <Icon type="ios-close-circle-outline" size="30" />
                                </div>
                            </div>
                            <div v-else>
                                <Upload :show-upload-list="false" :headers='headers' :action="imageAction"
                                    :on-format-error="handleFormatError" :format="['pem']"
                                    :on-success="uploadImgSuccess">
                                    <div class="upload_style">
                                        <Icon type="ios-add" size="60" />
                                        <div class="up_text">上传证书</div>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="xuxian"></div>
            <div class="s_b">
                <Button type="primary" @click="subBtn">确定</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { getCookies } from '@/utils/helper'
import config from '@/config/index'
import { h5pay, saveh5 } from "../../../api/interFace";
export default {
    name: "user",
    components: {
    },
    data() {
        return {
            inputVal: '',
            accessoriesList: '',
            imageAction: config.apiUrl + '/adminapi/common/uploads',
            headers: { 'Authori-zation': getCookies('token') },
            h5Paylist: {
                key: "",
                appid: "",
                mchid: "",
                serial_no: "",
                key_cert: ""
            },
        }
    },
    created() {
        this.getPay()
    },
    methods: {
        goBack() {
            this.$emit('goBack')
        },
        getPay() {
            h5pay().then(res => {
                this.h5Paylist = {
                    key: res.data.key,
                    appid: res.data.appid,
                    mchid: res.data.mchid,
                    serial_no: res.data.serial_no,
                    key_cert: res.data.key_cert
                }
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        subBtn() {
            if (this.h5Paylist.appid == '') {
                this.$Message.warning('请填写应用id'); return;
            }
            if (this.h5Paylist.mchid == '') {
                this.$Message.warning('商户号不能为空'); return;
            }
            if (this.h5Paylist.serial_no == '') {
                this.$Message.warning('微信支付平台证书序列号不能为空'); return;
            }
            if (this.h5Paylist.key == '') {
                this.$Message.warning('微信支付V3密钥不能为空'); return;
            }
            if (this.h5Paylist.key_cert == '') {
                this.$Message.warning('请上传微信支付私钥证书'); return;
            }
            saveh5(this.h5Paylist).then(res => {
                this.$Message.success(res.msg)
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        // 文件类型上传失败
        handleFormatError() {
            this.$Message.warning('暂不支持上传此类型文件')
        },
        // 判断类型上传文件
        uploadImgSuccess(e) {
            this.h5Paylist.key_cert = e.data.src
        },
    }
}
</script>

<style scoped lang="scss">
.user_page {
    .top_change {
        width: 100%;
        cursor: pointer;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 30px;
        box-sizing: border-box;
        height: 70px;
        .l_where {
            font-size: 18px;
            font-weight: bold;
            color: #1B2742;
            span {
                color: #4877E8;
            }
        }
        .r_back {
            .iconfont {
                font-size: 26px;
                cursor: pointer;
                &:hover {
                    color: #4877E8;
                }
            }
        }
    }
    .card_bg {
        width: 100%;
        margin-top: 20px;
        border-radius: 8px;
        background: #fff;
        padding: 50px 60px;
        box-sizing: border-box;
        min-height: 75vh;
        position: relative;
        .xuxian {
            width: 100%;
            border-top: 1px solid #e8eaec;
            position: absolute;
            bottom: 65px;
            margin-left: -60px;
        }
        .s_b {
            position: absolute;
            bottom: 20px;
            right: 24px;
        }
        .input_nav {
            margin-bottom: 25px;
            .label {
                font-size: 14px;
                font-weight: bold;
                color: #545F78;
                text-align: right;
                width: 180px;
            }
            .long {
                width: 130px;
            }
            .val {
                width: 350px;
                .up_text {
                    font-size: 14px;
                    color: #545F78;
                }
                .else_up {
                    display: flex;
                    .upload_style {
                        width: 100px;
                        height: 100px;
                        border-radius: 4px;
                        border: 1px solid #C8CEDA;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .pic_wrap {
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: 12px;
                    }
                    .img_list {
                        width: 100px;
                        height: 100px;
                        position: relative;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        border-radius: 4px;
                        border: 1px solid #C8CEDA;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 70px;
                            height: 70px;
                            object-fit: cover;
                            border-radius: 4px;
                        }
                        .close {
                            position: absolute;
                            top: -15px;
                            right: -15px;
                            cursor: pointer;
                            color: #dd0000;
                            display: none;
                        }
                    }
                    .img_list:hover .close {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
</style>
