import axios from 'axios'
import {Message} from 'view-design';
import config from '@/config';
import {getCookies} from '@/utils/helper'

const service = axios.create({
    baseURL: config.appletsBaseURL,
    timeout: 100000 // 请求超时时间
})

// axios.defaults.withCredentials = true// 携带cookie
// 请求拦截器
service.interceptors.request.use(
    config => {
        const token = getCookies('token')
        if (token) {
            // config.headers['Authori-zation'] = token
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        let status = response.data ? response.data.code : 0
        const code = status
        switch (code) {
        case 200:
            return response.data
        case 400:
            return Promise.reject(response.data || { msg: '未知错误' })
        default:
            break
        }
    },
    error => {
        console.log(error)
        Message.error(error.msg)
        return Promise.reject(error)
    }
)

export default service
